<template>
    <div class="home">
        <v-card>
            <v-container fluid>
                <v-row><v-col cols="12"></v-col></v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-card outlined>
                                <v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
                                    <div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
                                        TICK FILTER
                                    </div>
                                </v-card-title>

                                <v-row class="pa-4">
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                        <v-alert text class="mt-2 mb-0" color="info" icon="mdi-cloud-alert" border="left">
                                            <p class="mb-0">The tick filter allows user to fetch any period of previous days of any symbols' tick</p>
                                        </v-alert>
                                    </v-col>
                                </v-row>

                                <v-row class="pl-4 pr-4 pt-4">
                                    <v-col cols="12" sm="4" md="4" lg="3" xl="3" v-if="brokerMap.length > 1">
                                        <v-autocomplete label="Filter Broker" v-model="selectedBrokerId" :items="brokerMap"
                                            item-text="broker" item-value="broker_id" auto-select-first chips clearable deletable-chips
                                            small-chips></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4" lg="3" xl="3">
                                        <v-menu v-model="openMondayPicker" :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="selectedDate" label="Week Picker" prepend-icon="mdi-calendar"
                                                    readonly v-bind="attrs" v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="selectedDate" @input="openMondayPicker = false"
                                                :allowed-dates="allowedMonday"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4" lg="3" xl="3">
                                        <v-autocomplete v-model="tickSymbolValue" :items="tickSymbolListArray"
                                            v-if="tickSymbolListArray" label="Tick Symbol"></v-autocomplete>
                                    </v-col>
                                </v-row>

                                <v-row class="pl-4 pr-4">
                                    <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                                        <v-menu v-model="openStartDatePicker" :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="selectedStartDate" label="Start Date" prepend-icon="mdi-calendar"
                                                    readonly v-bind="attrs" v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="selectedStartDate" @input="openStartDatePicker = false"
                                                :allowed-dates="allowedPeriod"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                                        <v-menu ref="startTime" v-model="openStartTimePicker" :close-on-content-click="false"
                                            :nudge-right="40" :return-value.sync="selectedStartTime" transition="scale-transition"
                                            offset-y max-width="290px" min-width="290px">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="selectedStartTime" label="Start Time" readonly
                                                    prepend-icon="mdi-clock-time-four-outline" v-bind="attrs" v-on="on">
                                                </v-text-field>
                                            </template>
                                            <v-time-picker v-if="openStartTimePicker" v-model="selectedStartTime" full-width
                                                @click:second="$refs.startTime.save(selectedStartTime)" format="24hr" use-seconds>
                                            </v-time-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                                        <v-menu v-model="openEndDatePicker" :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="selectedEndDate" label="End Date" prepend-icon="mdi-calendar"
                                                    readonly v-bind="attrs" v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="selectedEndDate" @input="openEndDatePicker = false"
                                                :allowed-dates="allowedPeriod"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                                        <v-menu ref="endTime" v-model="openEndTimePicker" :close-on-content-click="false"
                                            :nudge-right="40" :return-value.sync="selectedEndTime" transition="scale-transition"
                                            offset-y max-width="290px" min-width="290px">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="selectedEndTime" label="End Time" readonly
                                                    prepend-icon="mdi-clock-time-four-outline" v-bind="attrs" v-on="on">
                                                </v-text-field>
                                            </template>
                                            <v-time-picker v-if="openEndTimePicker" v-model="selectedEndTime" full-width
                                                @click:second="$refs.endTime.save(selectedEndTime)" format="24hr" use-seconds>
                                            </v-time-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>

                                <v-row class="pl-4 pr-4">
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                        <div>
                                            <v-chip class="ma-2" color="secondary" small @click="oneMin">
                                                1 min
                                            </v-chip>
                                            <v-chip class="ma-2" color="secondary" small @click="fiveMin">
                                                5 min
                                            </v-chip>
                                            <v-chip class="ma-2" color="secondary" small @click="tenMin">
                                                10 min
                                            </v-chip>
                                            <v-chip class="ma-2" color="secondary" small @click="thirtyMin">
                                                30 min
                                            </v-chip>
                                            <v-chip class="ma-2" color="secondary" small @click="oneHour">
                                                1 hour
                                            </v-chip>
                                            <v-chip class="ma-2" color="secondary" small @click="oneDay">
                                                1 day
                                            </v-chip>
                                        </div>
                                    </v-col>
                                </v-row>

                                <v-row class="pl-4 pr-4 pb-4">
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                        <v-btn dense color="primary" v-on:click="loadChart()" :loading="btnSearchChartLoading">
                                            Show Chart</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-spacer></v-spacer>

                    <v-row v-if="loadResult">
                        <v-col cols="12">
                            <v-card outlined>
                                <v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
                                    <div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
                                        TICK CHARTS
                                    </div>
                                </v-card-title>

                                <v-row>
                                    <v-col cols="12">
                                        <TickSearchChart dom="dom1" :timeData="TickSearchChartTimeDate" :tickSymbolValue="tickSymbolValue"
                                            :seriesData="TickSearchChartSeriesData" :legendData="TickSearchLegendData" />
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>

            </v-container>
        </v-card>
    </div>
</template>
<script>
import { permission } from "@components/mixins/permission";
import { helper } from "@components/mixins/helper";
import { snackbar } from "@components/mixins/snackbar";
import { mapState } from "vuex";
import * as apiTickSymbolList from "@components/api/pk/tick/tick-symbol-list";
import * as apiTickSearchEcharts from "@components/api/pk/tick/tick-search-echarts";
import TickSearchChart from "./components/TickSearchChart";

export default {
    components: { TickSearchChart },
    mixins: [permission, helper, snackbar],
    data () {
        return {
            loadResult: false,
            selectedBrokerId: -1,
            selectedBroker: "",
            frontPermission: [],
            // 本周一
            // selectedDate: new Date(
            //     new Date().getTime() -
            //         ((new Date().getDay() || 7) - 1) * 24 * 60 * 60 * 1000
            // )
            //     .toISOString()
            //     .substr(0, 10),
            selectedDate: "",
            selectedStartDate: "",
            allowedStartDate: "",
            selectedEndDate: "",
            openMondayPicker: false,
            openStartDatePicker: false,
            openEndDatePicker: false,
            btnTickSymbolLoading: false,
            btnSearchChartLoading: false,
            tickSymbolList: {},
            tickSymbolListArray: [],
            tickSymbolValue: "",
            selectedStartTime: null,
            openStartTimePicker: false,
            selectedEndTime: null,
            openEndTimePicker: false,
            TickSearchChartTimeDate: [],
            TickSearchChartSeriesData: [],
            TickSearchLegendData: [],
            TickSearchChartSeriesDataSet: null
        };
    },
    computed: {
        ...mapState(["brokerMap", "snackmsg", "snackbar", "snackcolor"])
    },
    mounted () {
        // 获取当前账户对应权限的broker及broker id
        const brokerPermission = this.loadDefaultBrokerData();
        this.selectedBrokerId = brokerPermission.selectedBrokerId;
        this.selectedBroker = brokerPermission.selectedBroker;
        // 上周一
        this.selectedDate = new Date(
            new Date().setDate(
                new Date().getDate() - 7 - new Date().getDay() + 1
            )
        )
            .toISOString()
            .substr(0, 10);
    },
    watch: {
        selectedBrokerId (nv, ov) {
            this.selectedBroker = this.getBrokerNameById(nv);
            this.tickSymbolListArray = [];
            this.tickSymbolValue = "";
            if (nv === 4) {
                this.tickSymbolListArray = ["EURUSD-E"];
            } else {
                this.loadTickSymbolList();
            }
        },
        selectedDate (nv, ov) {
            const self = this;
            self.selectedStartDate = self.selectedDate;
            self.selectedStartTime = "00:00:00";
            self.selectedEndDate = "";
            self.selectedEndTime = "";
            // console.log(self.getMonday(self.selectedDate))
            // console.log(self.selectedDate)
            // self.allowedStartDate = self.selectedDate;
            self.allowedStartDate = self.getMonday(self.selectedDate);
            self.allowedEndDate = new Date(self.allowedStartDate);
            self.allowedEndDate.setDate(self.allowedEndDate.getDate() + 4);
            self.allowedEndDate = new Date(self.allowedEndDate)
                .toISOString()
                .substr(0, 10);
            // console.log(self.allowedStartDate,self.allowedEndDate)
            if (self.selectedBrokerId !== 4) {
                self.loadTickSymbolList();
            } else {
                self.tickSymbolListArray = ["EURUSD-E"];
            }
        }
    },
    methods: {
        getMonday (selectedDate) {
            const dd = new Date(selectedDate);
            const week = dd.getDay();
            const minus = week ? week - 1 : 6;
            dd.setDate(dd.getDate() - minus);
            const y = dd.getFullYear();
            let m = dd.getMonth() + 1;
            if (m < 10 && m > 0) m = "0" + m;
            let d = dd.getDate();
            if (d < 10 && d > 0) d = "0" + d;
            return y + "-" + m + "-" + d;
        },
        // 限定可以选择的周为本周前的所有周一
        allowedMonday: (val) =>
            new Date(val).getDay() >= 1 &&
            new Date(val).getDay() <= 5 &&
            // val <= new Date().toISOString().substr(0, 10)&&
            // 日期小于本周一
            val <
                new Date(
                    new Date().setDate(
                        new Date().getDate() - new Date().getDay() + 1
                    )
                )
                    .toISOString()
                    .substr(0, 10),
        // 选定周后，当周周一至周五为选择范围
        allowedPeriod (val) {
            const self = this;
            return (
                new Date(val).getDay() !== 6 &&
                new Date(val).getDay() !== 0 &&
                // val >= self.selectedStartDate &&
                val >= self.allowedStartDate &&
                val <= self.allowedEndDate &&
                val <= new Date().toISOString().substr(0, 10)
            );
        },
        // 加载symbol list
        loadTickSymbolList () {
            const self = this;
            self.tickSymbolValue = "";
            const params = {
                broker_id: self.selectedBrokerId,
                monday_date: self.getMonday(self.selectedDate)
            };
            self.btnTickSymbolLoading = true;
            apiTickSymbolList
                .fetch(params)
                .then((res) => {
                    self.tickSymbolList = res.data;
                    self.tickSymbolListArray = Object.values(
                        self.tickSymbolList
                    );
                    self.btnTickSymbolLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    self.btnTickSymbolLoading = false;
                    self.$store.state.snacktimeout = 2500;
                    self.$store.state.snackbar = true;
                    self.$store.state.snackcolor = "error";
                    self.$store.state.snackmsg = "No Symbol Data Found";
                    self.tickSymbolList = {};
                    self.tickSymbolListArray = [];
                    self.tickSymbolValue = "";
                });
        },
        /**
         * 加载图表数据
         * @return {[type]} [description]
         */
        loadChart () {
            const self = this;
            const brokerId = self.selectedBrokerId;
            const params = {
                broker_id: brokerId,
                symbol: self.tickSymbolValue,
                start_time:
                    self.selectedStartDate + " " + self.selectedStartTime,
                end_time: self.selectedEndDate + " " + self.selectedEndTime
            };

            if (self.checkEmptyField(self.tickSymbolValue)) {
                self.snackBarDanger("Please select symbol.");
                return;
            }

            if (self.checkEmptyField(self.selectedStartDate)) {
                self.snackBarDanger("Please select start date.");
                return;
            }

            if (self.checkEmptyField(self.selectedStartTime)) {
                self.snackBarDanger("Please select start time.");
                return;
            }

            if (self.checkEmptyField(self.selectedEndDate)) {
                self.snackBarDanger("Please select end date.");
                return;
            }

            if (self.checkEmptyField(self.selectedEndTime)) {
                self.snackBarDanger("Please select end time.");
                return;
            }

            self.loadResult = true;

            self.btnSearchChartLoading = true;

            apiTickSearchEcharts.fetch(params).then((res) => {
                self.btnSearchChartLoading = false;
                if (res.data.xdata.length === 0) {
                    self.snackBarDanger("No Data Found!");
                    return;
                }
                self.TickSearchChartSeriesData = [];
                self.TickSearchLegendData = [];
                self.TickSearchChartTimeDate = res.data.xdata;
                self.TickSearchChartTimeDate =
                    self.TickSearchChartTimeDate.map(function (str) {
                        return str.substring(5, str.length - 3);
                    });
                const askData = {
                    name: "Ask",
                    type: "line",
                    symbolSize: 8,
                    // hoverAnimation: false,
                    emphasis: { scale: false },
                    symbol: "circle",
                    data: res.data.ydata.ask
                };
                const bidData = {
                    name: "Bid",
                    type: "line",
                    symbolSize: 8,
                    // hoverAnimation: false,
                    emphasis: { scale: false },
                    symbol: "circle",
                    data: res.data.ydata.bid
                };
                const spreadData = {
                    name: "Spread",
                    type: "line",
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    symbolSize: 8,
                    // hoverAnimation: false,
                    emphasis: { scale: false },
                    symbol: "circle",
                    data: res.data.ydata.spread
                };
                self.TickSearchLegendData.push(askData.name);
                self.TickSearchChartSeriesData.push(askData);
                self.TickSearchLegendData.push(bidData.name);
                self.TickSearchChartSeriesData.push(bidData);
                self.TickSearchLegendData.push(spreadData.name);
                self.TickSearchChartSeriesData.push(spreadData);
                // self.$nextTick(() => {
                self.loadResult = true;
                // })
            })
                .catch((error) => {
                    console.log(error);
                    self.btnSearchChartLoading = false;
                    self.snackBarDanger("No Data Found!");
                });
        },
        oneMin () {
            const self = this;
            const time = self.selectedStartDate + " " + self.selectedStartTime;
            let date = new Date(
                new Date(time).setMinutes(new Date(time).getMinutes() + 1)
            );
            date = date.toLocaleString("en-GB");
            self.selectedEndDate =
                date.substr(6, 4) +
                "-" +
                date.substr(3, 2) +
                "-" +
                date.substr(0, 2);
            self.selectedEndTime = date.substr(12, 8);
        },
        fiveMin () {
            const self = this;
            const time = self.selectedStartDate + " " + self.selectedStartTime;
            let date = new Date(
                new Date(time).setMinutes(new Date(time).getMinutes() + 5)
            );
            date = date.toLocaleString("en-GB");
            self.selectedEndDate =
                date.substr(6, 4) +
                "-" +
                date.substr(3, 2) +
                "-" +
                date.substr(0, 2);
            self.selectedEndTime = date.substr(12, 8);
        },
        tenMin () {
            const self = this;
            const time = self.selectedStartDate + " " + self.selectedStartTime;
            let date = new Date(
                new Date(time).setMinutes(new Date(time).getMinutes() + 10)
            );
            date = date.toLocaleString("en-GB");
            self.selectedEndDate =
                date.substr(6, 4) +
                "-" +
                date.substr(3, 2) +
                "-" +
                date.substr(0, 2);
            self.selectedEndTime = date.substr(12, 8);
        },
        thirtyMin () {
            const self = this;
            const time = self.selectedStartDate + " " + self.selectedStartTime;
            let date = new Date(
                new Date(time).setMinutes(new Date(time).getMinutes() + 30)
            );
            date = date.toLocaleString("en-GB");
            self.selectedEndDate =
                date.substr(6, 4) +
                "-" +
                date.substr(3, 2) +
                "-" +
                date.substr(0, 2);
            self.selectedEndTime = date.substr(12, 8);
        },
        oneHour () {
            const self = this;
            const time = self.selectedStartDate + " " + self.selectedStartTime;
            let date = new Date(
                new Date(time).setMinutes(new Date(time).getMinutes() + 60)
            );
            date = date.toLocaleString("en-GB");
            self.selectedEndDate =
                date.substr(6, 4) +
                "-" +
                date.substr(3, 2) +
                "-" +
                date.substr(0, 2);
            self.selectedEndTime = date.substr(12, 8);
        },
        oneDay () {
            const self = this;
            const time = self.selectedStartDate + " " + self.selectedStartTime;
            let date = new Date(
                new Date(time).setMinutes(new Date(time).getMinutes() + 60 * 24)
            );
            date = date.toLocaleString("en-GB");
            self.selectedEndDate =
                date.substr(6, 4) +
                "-" +
                date.substr(3, 2) +
                "-" +
                date.substr(0, 2);
            self.selectedEndTime = date.substr(12, 8);
        }
    }
};
</script>
