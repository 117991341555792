<template>
    <div :id="dom" style="height:800px;margin-top:50px"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
    props: {
        dom: null,
        legendData: null,
        timeData: null,
        seriesData: null,
        tickSymbolValue: null
    },
    data () {
        return {
            chart: null,
            option: {
                title: [
                    {
                        text: this.tickSymbolValue + " Tick",
                        left: "center",
                        textStyle: { color: "#EEF1FA" }
                    },
                    {
                        top: "48%",
                        left: "center",
                        text: this.tickSymbolValue + " Spread",
                        textStyle: { color: "#EEF1FA" }
                    }
                ],
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        animation: false
                    }
                },
                legend: {
                    data: this.legendData,
                    left: 10,
                    textStyle: { color: "#B9B8CE" }
                },
                toolbox: {
                    feature: {
                        dataZoom: {
                            yAxisIndex: "none"
                        },
                        restore: {},
                        saveAsImage: {}
                    },
                    iconStyle: {
                        borderColor: "#B9B8CE",
                        color: "none"
                    }
                },
                axisPointer: {
                    link: { xAxisIndex: "all" }
                },
                dataZoom: [
                    {
                        show: true,
                        realtime: true,
                        start: 0,
                        end: 100,
                        xAxisIndex: [0, 1]
                    },
                    {
                        type: "inside",
                        realtime: true,
                        start: 0,
                        end: 100,
                        xAxisIndex: [0, 1]
                    }
                ],
                grid: [
                    {
                        left: 80,
                        right: 50,
                        height: "35%"
                    },
                    {
                        left: 80,
                        right: 50,
                        top: "55%",
                        height: "35%"
                    }
                ],
                xAxis: [
                    {
                        type: "category",
                        boundaryGap: false,
                        axisLine: { onZero: true },
                        data: this.timeData
                    },
                    {
                        gridIndex: 1,
                        type: "category",
                        boundaryGap: false,
                        axisLine: { onZero: true },
                        data: this.timeData
                        // position: "top",
                    }
                ],
                yAxis: [
                    {
                        // name: "单位1",
                        type: "value",
                        scale: true
                    },
                    {
                        gridIndex: 1,
                        // name: "单位2",
                        type: "value"
                        // inverse: true,
                    }
                ],
                series: this.seriesData,
                darkMode: true,
                color: [
                    "#4992ff",
                    "#7cffb2",
                    "#fddd60",
                    "#ff6e76",
                    "#58d9f9",
                    "#05c091",
                    "#ff8a45",
                    "#8d48e3",
                    "#dd79ff"
                ],
                backgroundColor: "#1e1e1e",
                textStyle: {
                    color: "#B9B8CE"
                }
            }
        };
    },
    methods: {
        init () {
            const self = this;
            if (
                self.chart != null &&
                self.chart != "" &&
                self.chart != undefined
            ) {
                self.chart.dispose();
            }
            self.chart = echarts.init(document.getElementById(self.dom));
            self.chart.setOption(self.option, true);
            window.addEventListener("resize", function () {
                self.chart.resize();
            });
        },
        beforeDestroy () {
            if (this.chart) {
                this.chart.clear();
            }
        }
    },
    mounted () {
        // this.init();
    },
    watch: {
        timeData (nv, ov) {
            this.option.xAxis[0].data = nv;
            this.option.xAxis[1].data = nv;
            if (this.chart) {
                this.chart.clear();
            }
            this.init();
        },
        seriesData (nv, ov) {
            this.option.series = nv;
            if (this.chart) {
                this.chart.clear();
            }
            this.init();
        },
        legendData (nv, ov) {
            this.option.legend.data = nv;
            if (this.chart) {
                this.chart.clear();
            }
            this.init();
        },
        tickSymbolValue (nv, ov) {
            this.option.title[0].text = nv + " Tick";
            this.option.title[1].text = nv + " Spread";
            if (this.chart) {
                this.chart.clear();
            }

            // if (this.chart) {
            //     this.chart.clear();
            // }
            // this.init();
        }
    }
};
</script>
